<!--
 * @Description: 搜索模板配置
 * @Autor: WangYuan
 * @Date: 2021-06-10 19:31:01
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-09 20:29:49
-->
<template>
  <div class="wrap">

    <!-- 搜索展示 -->
    <div style="padding-right:360px">
      <phone-ctn>
        <search-bar></search-bar>
      </phone-ctn>
    </div>

  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar";
import { mapGetters } from "vuex";

export default {
  name: "navigation",

  components: {
    SearchBar,
  },

  computed: {
    ...mapGetters(["project"]),
  },

  methods: {
    add() {
      this.project.config.hotList.push({ value: "" });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>